<template>
  <div>
    <div class="filter-container custom-filter-container p-2" :class="['sticky-header', { scrolled: isScrolled }]">
       <div class="filter-cotent">
          <div class="dropdown-wrapper" v-click-outside="closeEventDropdown">
            <button 
              class="dropdown-button"
              :class="{ 'has-value': allFilters.eventFilter }"
              @click="toggleEventDropdown"
            >
              <span>{{ selectedEventName || 'Event' }}</span>
              <img
                class="close-down"
                :src="arrow_down"
                alt="icon"
              />
            </button>
            <div class="dropdown-menu-content" v-show="isEventDropdownOpen">
              <div 
                v-for="option in teamEventInfo" 
                :key="option.id" 
                class="dropdown-item"
                @click="selectEvent(option)"
              >
                <span>{{ option.eventName }}</span>
              </div>
            </div>
          </div>

          <div class="dropdown-wrapper" v-click-outside="closeEventTypeDropdown">
            <button 
              class="dropdown-button"
              :class="{ 'has-value': allFilters.eventType }"
              @click="toggleEventTypeDropdown"
            >
              <span>{{ selectedEventType || 'Type' }}</span>
              <img
                class="close-down"
                :src="arrow_down"
                alt="icon"
                
              />
            </button>
            <div class="dropdown-menu-content" v-show="isEventTypeDropdownOpen">
              <div class="dropdown-item" @click="selectEventType('League')">
                <span>League</span>
              </div>
              <div class="dropdown-item" @click="selectEventType('Tournament')">
                <span>Tournament</span>
              </div>
            </div>
          </div>

          <div class="dropdown-wrapper" v-click-outside="closeAssociationDropdown">
            <button 
              class="dropdown-button"
              :class="{ 'has-value': allFilters.associationFilter }"
              @click="toggleAssociationDropdown"
            >
              <span>{{ selectedAssociation || 'Association' }}</span>
              <img
                class="close-down"
                :src="arrow_down"
                alt="icon"
               
              />
            </button>
            <div class="dropdown-menu-content" v-show="isAssociationDropdownOpen">
              <div
                v-for="option in associationOptions"
                :key="option"
                class="dropdown-item"
                @click="selectAssociation(option)"
              >
                <span>{{ option }}</span>
              </div>
            </div>
          </div>
        </div>
        <button class="reset-button" @click="resetFilters" v-if="isResetVisible">Reset Filters</button>
    </div>

    <b-row class="no-gutters" v-if="gameLineUpss.length > 0 && showLoader == 0">
      <div class="col-12 products-column">
        <div class="event-card">
          <div class="table-responsive mb-0">
            <b-table
              :items="gameLineUpss"
              :fields="fields"
              hover
              responsive
              class="b-datatable stat-datatable"
            >
              <template v-slot:top-row class="tbody-tr-attr">
                <th class="text-left">Total</th>
                <th class="text-center">{{ totalStats.games_played }}</th>
                <th class="text-center" v-if="showBase">
                  {{ totalStats.on_base_avg }}
                </th>
                <th class="text-center" v-if="!showBase">
                  {{ totalStats.on_base_avg }}
                </th>
                <th class="text-center" v-if="!showBase">
                  {{ totalStats.average }}
                </th>
                <th class="text-center">{{ totalStats.ab }}</th>
                <th class="text-center">{{ totalStats.h }}</th>
                <th class="text-center">{{ totalStats.one_b }}</th>
                <th class="text-center">{{ totalStats.two_b }}</th>
                <th class="text-center">{{ totalStats.three_b }}</th>
                <th class="text-center">{{ totalStats.hr }}</th>
                <th class="text-center">{{ totalStats.rbi }}</th>
                <th class="text-center">{{ totalStats.r }}</th>
                <th class="text-center">{{ totalStats.bb }}</th>
                <th class="text-center">{{ totalStats.sac }}</th>
                <th class="text-center">{{ totalStats.e }}</th>
              </template>
              <!-- Original template for cell(Lineup) -->
              <template v-slot:cell(Lineup)="data">
                <div class="playerInfo">
                  <span class="position-tag">{{data.item.position[0].position_name }}</span>
                  <div class="shirt-no">
                    <img src="../../assets/images/shirt.png" alt="Image" />
                    <span>{{
                      data?.item?.teamMember?.uniform_no
                        ? data.item?.teamMember?.uniform_no
                        : "-"
                    }}</span>
                  </div>
                  <span class="player-name">
                    {{ data.item?.user?.name || data.item?.user?.email }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(stats.on_base_avg)="data">
                <div class="text-center pl-2">
                  {{
                    data.item.stats.on_base_avg !== null
                      ? data.item.stats.on_base_avg
                      : "-"
                  }}
                </div>
              </template>

              <template v-slot:cell(stats.average)="data">
                <div class="text-center pl-2">
                  {{
                    data.item.stats.average !== null
                      ? data.item.stats.average
                      : "-"
                  }}
                </div>
              </template>
              <!-- <template v-slot:cell(stats.on_base_avg)="data">
            <td class="text-center">
              {{ showBase ? (data.item.stats.average !== null ? data.item.stats.average : "-") : (data.item.stats.on_base_avg !== null ? data.item.stats.on_base_avg : "-") }}
            </td>
          </template> -->
            </b-table>
          </div>
        </div>
      </div>
    </b-row>

    <div class="no-event-wrap" v-if="showLoader == 0 && gameLineUpss.length == 0">
      <img src="../../assets/Svg/no-data.svg" alt />
      <p class="header">No Player Statistics Found</p>
      <p class="info">Individual player statistics will appear here. Change the filter above or Head over to <router-link to="/my-events" class="mx-0">Events</router-link> create games, lineup and enter team record to start generating statistics.</p>
    </div>

    <div class="row no-gutters mt-3" v-if="showLoader == 1">
      <div class="col-12 p-2">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
let eventType = require("../../assets/js/eventType");

export default {
  data: function () {
    return {
      arrow_down: require("../../assets/Svg/arrow-down.svg"),
      crossIcon: require("../../assets/images/cross.png"),
      associationOptions: [],
      eventTypeOptions: eventType,
      sortKey: "",
      reverse: false,
      showBase: false,
      teamDataInfo: {},
      teamEventInfo: {},
      totalNumberOfPlayers: 0,
      eventLoader: 1,
      allFilters: {
        eventType: "",
        associationFilter: "",
        eventFilter: "",
      },
      teamId: "",

      isEventTypeDropdownOpen: false,
      isAssociationDropdownOpen: false,
      isEventDropdownOpen: false,

      activeDropdown: null,
      selectedEventName: '',
      selectedEventType: '',
      selectedAssociation: '',
      isScrolled: false,
    };
  },
  props: {
    gameLineUps: {
      type: Array,
    },
    gameLineUpss: {
      type: Array,
    },
    totalStats: {
      type: Object,
    },
    showLoader: {
      type: Number,
    },
    selectedRoomTeam: {
      type: Object,
    },
    // teamId: {
    //   type: String,
    // },
  },
  computed: {
     isResetVisible() {
      return (
        this.allFilters.associationFilter !== "" ||
        this.allFilters.eventFilter !==  "" ||
        this.allFilters.eventType !== ""
      );
    },
    sortedGameLineUps() {
      const lineUps = this.gameLineUps.slice();
      if (this.sortKey) {
        lineUps.sort((a, b) => {
          const fieldA = a.stats[this.sortKey];
          const fieldB = b.stats[this.sortKey];

          if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else {
            return 0;
          }
        });
      }
      return lineUps;
    },
    fields() {
      let baseFields = [
        { key: "Lineup", label: "Lineup", sortable: false, class: "text-left pl-2", },
        {
          key: "stats.games_played",
          label: "Games Played",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.ab",
          label: "AB",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.h",
          label: "H",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.one_b",
          label: "1B",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.two_b",
          label: "2B",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.three_b",
          label: "3B",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.hr",
          label: "HR",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.rbi",
          label: "RBI",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.r",
          label: "R",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.bb",
          label: "BB",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.sac",
          label: "SAC",
          sortable: true,
          class: "text-center pl-2",
        },
        {
          key: "stats.e",
          label: "E",
          sortable: true,
          class: "text-center pl-2",
        },
      ];

      if (!this.showBase) {
        baseFields.splice(2, 0, {
          key: "stats.on_base_avg",
          label: "Onbase %",
          sortable: true,
          class: "text-center pl-2",
        });
        baseFields.splice(3, 0, {
          key: "stats.average",
          label: "Average",
          sortable: true,
          class: "text-center pl-2",
        });
      } else {
        baseFields.splice(2, 0, {
          key: "stats.on_base_avg",
          label: "Average",
          sortable: true,
          class: "text-center pl-2",
        });
      }

      return baseFields;
    },
  },
  methods: {
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 100;
    },
    getDivs(data) {
      if (data == 1) {
        this.showBase = true;
      } else if (data == 0) {
        this.showBase = false;
      }
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = true;
      }
    },
    getTotal(columnName) {
      return this.gameLineUpss.reduce(
        (total, item) => total + item.stats[columnName],
        0
      );
    },
    resetFilters() {
      this.allFilters.eventFilter = "";
      this.selectedEventName = "";
      this.selectedEventType = "";
      this.selectedAssociation = "";
      this.allFilters.associationFilter = "";
      this.allFilters.eventType = "";
      this.applyFilter()
    },
    toggleEventDropdown() {
      this.isEventDropdownOpen = !this.isEventDropdownOpen;
    },
    closeEventDropdown() {
      this.isEventDropdownOpen = false;
    },
    toggleEventTypeDropdown() {
      this.isEventTypeDropdownOpen = !this.isEventTypeDropdownOpen;
    },
    closeEventTypeDropdown() {
      this.isEventTypeDropdownOpen = false;
    },
    toggleAssociationDropdown() {
      this.isAssociationDropdownOpen = !this.isAssociationDropdownOpen;
    },
    closeAssociationDropdown() {
      this.isAssociationDropdownOpen = false;
    },
    
    selectEvent(option) {
      this.allFilters.eventFilter = option.id;
      this.selectedEventName = option.eventName;
      this.applyFilter();
      this.closeEventDropdown();
    },
    clearEvents() {
      this.allFilters.eventFilter = "";
      this.selectedEventName = '';
      this.applyFilter();
    },
    selectEventType(type) {
      this.allFilters.eventType = type;
      this.selectedEventType = type;
      this.applyFilter();
      this.closeEventTypeDropdown();
    },
    clearEventType() {
      this.allFilters.eventType = '';
      this.selectedEventType = '';
      this.applyFilter();
    },
    selectAssociation(option) {
      this.allFilters.associationFilter = option;
      this.selectedAssociation = option;
      this.applyFilter();
      this.closeAssociationDropdown();
    },
    clearAssociation() {
      this.allFilters.associationFilter = "";
      this.selectedAssociation = '';
      this.applyFilter();
    },
    applyFilter() {
      this.$root.$emit("teamPlayerStatFilter", this.allFilters);
    },
    getTeamEvents() {
      this.eventLoader = 1;
      const formData = new FormData();
      formData.append("team_id", this.teamId);
      axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventTypeLeagueTournamentList",
          formData
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamEventInfo = response.data.data;
            this.eventLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.eventLoader = 0;
          }
        });
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    this.getAssociation();
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getTeamEvents();
    }
    this.$root.$on("onBaseShowAvg", (data) => {
      this.teamDataInfo = data;
      if (
        this.teamDataInfo.team &&
        this.teamDataInfo.team.show_on_base_avg == 1
      ) {
        this.showBase = true;
      }
    });
    this.$root.$on("onBaseShowAvgValue", (data) => {
      this.getDivs(data);
    });
    // this.$root.$on("HitTeamsHistory", () => {
    //   this.fetchLineups();
    // });
    // this.$root.$on("callLineupAfterUpdate", () => {
    //   this.fetchLineups();
    // });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
.lds-dual-ring-media:after {
  width: 20px;
  height: 20px;
}
.shop-products-img-loader {
  position: absolute;
  top: 50%;
  left: 90% !important;
  z-index: 99;
}

.teams-table {
  overflow: auto;
}
.teams-table table thead tr th {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.loader div {
  display: contents !important;
}
.teams-table table tbody tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  vertical-align: middle !important;
  text-align: center;
}
.teams-table tbody tr td div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.teams-table tbody tr td small {
  background-color: #ffffff;
  border: 1px solid #188ef5;
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.playerInfo {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>
