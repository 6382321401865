<template>
  <div>
    <div class="filter-container custom-filter-container p-2" :class="['sticky-header', { scrolled: isScrolled }]">
      <div class="filter-cotent">
        <div class="dropdown-wrapper" v-click-outside="closeEventDropdown">
          <button 
            class="dropdown-button"
            :class="{ 'has-value': selectedEvent }"
            @click="toggleEventDropdown"
          >
            <span>{{ selectedEvent || 'Event' }}</span>
            <img :src="arrow_down" alt="arrow-down" />
          </button>
          <div class="dropdown-menu-content" v-show="isEventDropdownOpen">
            <div
              v-for="option in teamEventInfo"
              :key="option.id"
              class="dropdown-item"
              @click="selectEvent(option)"
            >
              <span>{{ option.eventName }}</span>
            </div>
          </div>
        </div>

        <div class="dropdown-wrapper" v-click-outside="closeAssociationDropdown">
          <button 
            class="dropdown-button"
            :class="{ 'has-value': selectedAssociation }"
            @click="toggleAssociationDropdown"
          >
            <span>{{ selectedAssociation || 'Association' }}</span>
            <img :src="arrow_down" alt="arrow-down" />
          </button>
          <div class="dropdown-menu-content" v-show="isAssociationDropdownOpen">
            <div
              v-for="option in associationOptions"
              :key="option"
              class="dropdown-item"
              @click="selectAssociation(option)"
            >
              <span>{{ option }}</span>
            </div>
          </div>
        </div>
      </div>
      <button class="reset-button" @click="resetFilters" v-if="isResetVisible">Reset Filters</button>
    </div>

    <b-row class="no-gutters" v-if="gameLineUps.length > 0 && showLoader == 0">
      <div class="col-12 products-column">
        <div class="event-card">
          <div class="table-responsive mb-0">
            <b-table
              :items="gameLineUps"
              :fields="fields"
              hover
              responsive
              class="b-datatable"
            >
              <template v-slot:top-row class="tbody-tr-attr">
                <th>Total</th>
                <th v-if="showBase">
                  {{ totalStats.on_base_avg }}
                </th>
                <th v-if="!showBase">
                  {{ totalStats.on_base_avg }}
                </th>
                <th v-if="!showBase">
                  {{ totalStats.average }}
                </th>
                <th>{{ totalStats.ab }}</th>
                <th>{{ totalStats.h }}</th>
                <th>{{ totalStats.one_b }}</th>
                <th>{{ totalStats.two_b }}</th>
                <th>{{ totalStats.three_b }}</th>
                <th>{{ totalStats.hr }}</th>
                <th>{{ totalStats.rbi }}</th>
                <th>{{ totalStats.r }}</th>
                <th>{{ totalStats.bb }}</th>
                <th>{{ totalStats.sac }}</th>
                <th>{{ totalStats.e }}</th>
              </template>

              <!-- Original template for cell(Lineup) -->
              <template v-slot:cell(Lineup)="data">
                <div class="playerInfo" v-if="data?.item?.username">
                  <div class="shirt-no">
                    <img src="../../assets/images/shirt.png" alt="Image" />
                    <span>{{
                      data.item?.userRole ? data.item?.userRole?.uniform : 0
                    }}</span>
                  </div>
                  <span class="player-name">{{ data?.item?.username }}</span>
                </div>
                <content-placeholders :rounded="true" v-else>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </template>
              <template v-slot:cell(game.opponent_name)="data">
                <span class="datatable-date-time">{{ formatDateTime(data.item.game.start_date) }} -
                {{ data.item.game.start_time | formatTimeToAM }}</span>
               
                <div class="played-data d-flex flex-wrap align-items-center" style="gap: 5px;">
                  <span class="tie" v-if="data.item.game_live == 1">
                    {{ data.item.game.team_score }} vs
                    {{ data.item.game.opponent_score }}
                  </span>

                  <span class="tie" v-else-if="data.item.game.win_status == 0">
                    {{ data.item.game.team_score }} vs
                    {{ data.item.game.opponent_score }}
                  </span>

                  <span
                    class="win-data"
                    v-else-if="data.item.game.win_status == 1"
                  >
                    <span class="position-tag">Won</span>{{ data.item.game.team_score }} vs
                    {{ data.item.game.opponent_score }}
                  </span>

                  <span
                    class="loss-data"
                    v-else-if="data.item.game.win_status == 2"
                  >
                    <span class="position-tag">Lost</span> {{ data.item.game.team_score }} vs
                    {{ data.item.game.opponent_score }}
                  </span>

                  <span class="tie" v-else-if="data.item.game_start != 1">
                    Not Started Yet
                  </span>

                  <span class="tie" v-else>
                    {{ data.item.game.team_score }} vs
                    {{ data.item.game.opponent_score }}
                  </span>
                   <span class="team-oppo">
                     {{ data.item.game.opponent_name }}
                  </span>
                </div>
                <span class="datatable-event-name">{{ data.item.event.eventName }}</span>
              </template>

              <template v-slot:cell(stats.on_base_avg)="data">
                <div>
                  {{
                    data.item.stats.on_base_avg !== null
                      ? data.item.stats.on_base_avg
                      : "-"
                  }}
                </div>
              </template>

              <template v-slot:cell(stats.average)="data">
                <div>
                  {{
                    data.item.stats.average !== null
                      ? data.item.stats.average
                      : "-"
                  }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-row>

    <div
      class="no-event-wrap"
      v-if="showLoader == 0 && gameLineUps.length == 0"
    >
      <img src="../../assets/Svg/no-data.svg" alt />
      <p class="header">No Team Statistics Found</p>
      <p class="info">
        Team statistics will appear here. Change the filter above or Head over to <router-link to="/my-events" class="mx-0">Events</router-link> create games, lineup and enter team record to start generating statistics.
      </p>
    </div>

    <div class="row no-gutters" v-if="showLoader == 1">
      <div class="col-12 p-2">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
let eventType = require("../../assets/js/eventType");

export default {
  data: function () {
    return {
      arrow_down: require("../../assets/Svg/arrow-down.svg"),
      crossIcon: require("../../assets/images/cross.png"),
      associationOptions: [],
      eventTypeOptions: eventType,
      sortKey: "",
      reverse: false,
      showBase: false,
      teamDataInfo: {},
      totalNumberOfPlayers: 0,
      teamEventInfo: {},
      eventLoader: 1,
      allFilters: {
        eventType: "",
        associationFilter: "",
        eventFilter: "",
      },
      teamId: "",

      selectedEvent: '',
      selectedAssociation: '',

      isAssociationDropdownOpen: false,
      isEventDropdownOpen: false,
      isScrolled: false,
    };
  },
  props: {
    gameLineUps: {
      type: Array,
    },
    totalStats: {
      type: Object,
    },
    showLoader: {
      type: Number,
    },
    selectedRoomTeam: {
      type: Object,
    },
    // teamId: {
    //   type: String,
    // },
  },
  computed: {
    isResetVisible() {
      return (
        this.allFilters.associationFilter !== "" ||
        this.allFilters.eventFilter !==  ""
      );
    },
    fields() {
      let baseFields = [
        { key: "game.opponent_name", label: "", sortable: false }, // Disable sorting for Lineup column
        // {
        //   key: "game.start_date",
        //   label: "Date",
        //   sortable: true,
        //   class: "text-left",
        // },
        // {
        //   key: "event.eventName",
        //   label: "Event",
        //   sortable: true,
        //   class: "text-left",
        // },

        { key: "stats.ab", label: "AB", sortable: true, class: "text-left" },
        { key: "stats.h", label: "H", sortable: true, class: "text-left" },
        { key: "stats.one_b", label: "1B", sortable: true, class: "text-left" },
        { key: "stats.two_b", label: "2B", sortable: true, class: "text-left" },
        {
          key: "stats.three_b",
          label: "3B",
          sortable: true,
          class: "text-left",
        },
        { key: "stats.hr", label: "HR", sortable: true, class: "text-left" },
        { key: "stats.rbi", label: "RBI", sortable: true, class: "text-left" },
        { key: "stats.r", label: "R", sortable: true, class: "text-left" },
        { key: "stats.bb", label: "BB", sortable: true, class: "text-left" },
        { key: "stats.sac", label: "SAC", sortable: true, class: "text-left" },
        { key: "stats.e", label: "E", sortable: true, class: "text-left" },
      ];

      if (!this.showBase) {
        baseFields.splice(1, 0, {
          key: "stats.on_base_avg",
          label: "Onbase %",
          sortable: true,
          class: "text-left",
        });
        baseFields.splice(2, 0, {
          key: "stats.average",
          label: "Average",
          sortable: true,
          class: "text-left",
        });
      } else {
        baseFields.splice(1, 0, {
          key: "stats.on_base_avg",
          label: "Average",
          sortable: true,
          class: "text-left",
        });
      }

      return baseFields;
    },
  },
  methods: {
    handleScroll() {
      let scrollTop = document.querySelector('.shop-side')?.scrollTop || 0;
      this.isScrolled = scrollTop > 100;
    },
    resetFilters() {
      this.allFilters.eventFilter = "";
      this.allFilters.associationFilter = "";
      this.selectedEvent = "";
      this.selectedAssociation = "";
      this.applyFilter()
    },
    toggleEventDropdown() {
      this.isEventDropdownOpen = !this.isEventDropdownOpen;
    },
    closeEventDropdown() {
      this.isEventDropdownOpen = false;
    },
    toggleAssociationDropdown() {
      this.isAssociationDropdownOpen = !this.isAssociationDropdownOpen;
    },
    closeAssociationDropdown() {
      this.isAssociationDropdownOpen = false;
    },
    selectEvent(option) {
      this.allFilters.eventFilter = option.id;
      this.selectedEvent = option.eventName;
      this.applyFilter();
      this.closeEventDropdown()
    },
    clearEvents() {
      this.allFilters.eventFilter = '';
      this.selectedEvent = '';
      this.applyFilter();
    },
    selectAssociation(option) {
      this.allFilters.associationFilter = option;
      this.selectedAssociation = option;
      this.applyFilter();
      this.closeAssociationDropdown();
    },
    clearAssociation() {
      this.allFilters.associationFilter = '';
      this.selectedAssociation = '';
      this.applyFilter();
    },
    getDivs(data) {
      if (data == 1) {
        this.showBase = true;
      } else if (data == 0) {
        this.showBase = false;
      }
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = true;
      }
    },
    getTotal(columnName) {
      return this.gameLineUps.reduce(
        (total, item) => total + item.stats[columnName],
        0
      );
    },
    formatDateTime(dateString) {
      const optionsDate = { month: "short", day: "numeric", year: "2-digit" };
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en-US", optionsDate);
      return `${formattedDate}`;
    },
    // clearEventType() {
    //   this.allFilters.eventType = "";
    //   this.applyFilter();
    // },
    // clearAssociation() {
    //   this.allFilters.associationFilter = "";
    //   this.applyFilter();
    // },
    // clearEvents() {
    //   this.allFilters.eventFilter = "";
    //   this.applyFilter();
    // },
    applyFilter() {
      this.$root.$emit("teamStatisticsFilter", this.allFilters);
    },
    getTeamEvents() {
      this.eventLoader = 1;
      const formData = new FormData();
      formData.append("team_id", this.teamId);
      axios
        .post(
          process.env.VUE_APP_API_URL + "event/eventTypeLeagueTournamentList",
          formData
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamEventInfo = response.data.data;
            this.eventLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.eventLoader = 0;
          }
        });
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    this.getAssociation();
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getTeamEvents();
    }
    this.$root.$on("onBaseShowAvg", (data) => {
      this.teamDataInfo = data;
      if (
        this.teamDataInfo.team &&
        this.teamDataInfo.team.show_on_base_avg == 1
      ) {
        this.showBase = true;
      }
    });
    this.$root.$on("onBaseShowAvgValue", (data) => {
      this.getDivs(data);
    });
    // this.$root.$on("HitTeamsHistory", () => {
    //   this.fetchLineups();
    // });
    // this.$root.$on("callLineupAfterUpdate", () => {
    //   this.fetchLineups();
    // });
    let scrollContainer = document.querySelector('.shop-side'); // Update selector to match your container
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeUnmount() {
    let scrollContainer = document.querySelector('.shop-side');
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style scoped>
.lds-dual-ring-media:after {
  width: 20px;
  height: 20px;
}
.shop-products-img-loader {
  position: absolute;
  top: 50%;
  left: 90% !important;
  z-index: 99;
}
.teams-table {
  overflow: auto;
}
.teams-table table thead tr th {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.loader div {
  display: contents !important;
}
.teams-table table tbody tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  vertical-align: middle !important;
  text-align: center;
}
.teams-table tbody tr td div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.teams-table tbody tr td small {
  background-color: #ffffff;
  border: 1px solid #188ef5;
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.playerInfo {
  display: flex;
  align-items: center;
  gap: 5px;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>
