<template>
  <div>
    <TeamDirectMessageModal />
    <div class="team-details-wrap-top" v-if="selectedRoomTeam">
      <div class="team-details-top">
        <div class="data-info">
          <div class="image-team position-relative">
            <div class="users-notification" v-if="imgLoader == 1">
              <img src="../../assets/loader.gif" alt="" />
            </div>
            <img
              @load="onImageLoad"
              v-if="selectedRoomTeam.teamAvatar == ''"
              src="../../assets/Svg/people.svg"
              alt=""
            />
            <img
              @load="onImageLoad"
              v-if="selectedRoomTeam.teamAvatar != ''"
              :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
              alt=""
            />
          </div>
          <div>
            <div class="name-team">{{ selectedRoomTeam.teamName }}</div>
            <div class="type-team" v-if="selectedRoomTeam.teamType == 'sports'">
              {{ selectedRoomTeam.ageGroup }} {{ selectedRoomTeam.gender }}
            </div>
            <div
              class="type-team text-capitalize"
              v-if="selectedRoomTeam.teamType == 'sports'"
            >
              {{ selectedRoomTeam.teamType }} - {{ selectedRoomTeam.sportType }}
            </div>
            <div
              class="type-team text-capitalize"
              v-if="selectedRoomTeam.teamType !== 'sports'"
            >
              {{ selectedRoomTeam.teamType }}
            </div>
          </div>
        </div>
        <div class="play-options">
          <play
            :teamId="selectedRoomTeam.id"
            v-if="selectedRoomTeam.teamType == 'sports'"
          />
          <div class="team-details-btns">
            <router-link
              v-if="selectedRoomTeam && selectedRoomTeam.id"
              class="nav-item nav-link p-0 m-0"
              exact
              :to="`/chat/team/${selectedRoomTeam.id}`"
            >
              <button
                class="msg-btn"
                @click="setOpenTabChat('Team')"
              >
                <img src="../../assets/Svg/team/message-black.svg" alt="" />
                <span>Message Team</span>
              </button>
            </router-link>
            <!-- <button v-if="currentIsAdmin == '1'" class="team-options btn btn-secondary" @click="showHideInviteOptions(selectedRoomTeam)">
            <img src="../../assets/Svg/team/invite-teammates.svg" alt="" />
            <span>Invite to Team</span>
          </button> -->
            <TeamOptions
              :selectedRoomTeam="selectedRoomTeam"
              :currentUserId="currentUserId"
              :currentIsAdmin="currentIsAdmin"
              :currentIsFan="currentIsFan"
              :currentTeamIsActive="currentTeamIsActive"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamOptions from "../Teams/ChatRight/ChatSettingComponents/TeamOptions.vue";
import Play from "./Play.vue";
import TeamDirectMessageModal from "./TeamDirectMessageModal.vue";
export default {
  components: { Play, TeamOptions, TeamDirectMessageModal },
  name: "TeamDetailsTop",
  data() {
    return {
      inviteOptions: false,
      isActive: false,
      imgLoader: 1,
    };
  },

  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  methods: {
    setOpenTabChat(value) {
      // Set 'Team' in localStorage
      localStorage.setItem("openTabChat", value);
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    openTeamMessageModal() {
      this.$root.$emit(
        "fetchTeamDirectMessageModal",
        this.selectedRoomTeam,
        this.currentUserId
      );
    },
  },
};
</script>

<style>
.isActive {
  background: #188ef5 !important;
  color: #ffffff !important;
}

.isActive img {
  filter: brightness(0) invert(1) !important;
}
</style>