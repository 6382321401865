<template>
  <div class="" v-if="user">
    <div class="invalid mb-26" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-if="posts.length">
      <div class="post-view" v-for="post in posts" :key="post.id">
        <div class="user-info" style="margin-bottom: 16px">
          <div class="user-img">
            <div class="users-notification" v-if="imgLoader == 1">
                <!-- <div class="lds-dual-ring-refresh"></div> -->
                <img src="../../assets/loader.gif" alt="">
            </div>
            <span v-if="user.user_link == post.user_link">
              <router-link to="/profile" exact style="color: black">
                <img
                @load="onImageLoad"
                  v-if="post?.propicture == 'yes'"
                  :src="
                    Storage_URL +
                    post.user_id +
                    '/profile_picture.jpg?v=' +
                    time
                  "
                  alt=""
                />
                <img
                @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </router-link>
            </span>
            <span v-else>
              <router-link
                :to="/profile/ + post.user_link"
                exact
                style="color: black"
              >
                <img
                @load="onImageLoad"
                  v-if="post?.propicture == 'yes'"
                  :src="
                    Storage_URL +
                    post.user_id +
                    '/profile_picture.jpg?v=' +
                    time
                  "
                  alt=""
                />
                <img
                @load="onImageLoad"
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                />
              </router-link>
            </span>
          </div>
          <div class="user-details mr-auto">
            <span v-if="user.user_link == post.user_link">
              <router-link
                to="/profile"
                exact
                style="color: black"
                class="user-name ml-0"
                >{{ post.user_name }}</router-link
              >
            </span>
            <span v-else>
              <router-link
                :to="/profile/ + post.user_link"
                exact
                style="color: black"
                class="user-name ml-0"
                >{{ post.user_name }}</router-link
              >
            </span>
            <p>{{ post.created_at | formatDate }}</p>
          </div>
          <PostEdit
            :items="post"
            v-if="user.id == post.user_id"
            :openTab="1"
          ></PostEdit>
          <PostReport
            :items="post"
            v-if="user.id != post.user_id"
            :openTab="1"
          ></PostReport>
        </div>
        <div class="post-content">
          <PostContent :items="post.caption"></PostContent>
          <div class="post-images">
            <div class="gallery-container">
              <lightbox :items="post.post_images" class="newPostC"></lightbox>
            </div>
          </div>
        </div>
        <PeopleComments :items="post" :type="type" />
      </div>      
    </div>

    <div v-if="noPost == 1" class="see-all-seen-post-wrapper">
      <p>No posts found.</p>
    </div>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <!-- <br /> -->
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <!-- <br /> -->
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>

    <div
        v-if="posts.length"
        v-observe-visibility="handleScrolledToButtom"
        class="py-2"
      ></div>
    
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import lightbox from "../ImageGrid/lightbox";
import "../ImageGrid/lightbox";
import "../ImageGrid/lightbox.css";
import PostContent from "../Posts/PostContent";
import PostEdit from "../Posts/PostEdit";
import PostReport from "../Posts/PostReport";
import PeopleComments from "./PeopleComments.vue";

export default {
  name: "PostView",
  components: {
    lightbox,
    PostContent,
    PostEdit,
    PostReport,
    PeopleComments,
  },
  data() {
    return {
      imgLoader: 1,
      type: "opinion",
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      posts: [],
      page: 1,
      noPost: 0,
      showLoader: 0,
      lastPage: 1,
      API_URL: this.$userProfileStorageURL,
      postOptions: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    async fetch() {
      this.showLoader = 1;
      var post_id = localStorage.getItem("post_id_notify");
      let posts = await axios.get(
        `post/postListing?page=${this.page}&post_id=${post_id}`
      );
      if (posts.data.statusCode == 200) {
        if (posts.data.data.searchedPost) {
          this.posts.push(...posts.data.data.searchedPost);
          localStorage.setItem("post_id_notify", "");
        }
        this.posts.push(...posts.data.data.listing.data);
        this.lastPage = posts.data.data.listing.last_page;
        this.noPost = posts.data.data.listing.data.length > 0 ? 0 : 1;
      } else {
        this.errorMessage = posts.data.message;
      }
      this.showLoader = 0;
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      console.log( this.page, "this.page")
      this.fetch();
    },
  },
  mounted() {
    this.fetch(1);
    this.$root.$on("PostView", () => {
      this.page = 1;
      this.lastPage = 1;
      this.posts = [];
      this.fetch(1);
    });
  },
};
</script>
