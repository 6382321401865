<template>
<div>
  <servicesModal :singleEvent="singleEvent"/>
  <div v-if="currentIsAdmin == '1'" class="d-flex align-items-center justify-content-end">
    <CreateEventDetailModal
      :selectedRoomTeam="selectedRoomTeam"
      :currentIsAdmin="currentIsAdmin"
    />
  </div>
  <div class="event-section">
    <div :class="['sticky-header', { scrolled: isScrolled }]">
      <div class="filter-top mt-0">
        <div class="filter-container">
          <div class="filter-cotent">
            <div class="dropdown-wrapper" v-click-outside="closeYearDropdown">
              <button
                class="dropdown-button"
                :class="{ 'has-value': filter.startDateFilter }"
                @click="toggleYearDropdown"
                :disabled="isLoading"
              >
                <span>{{ filter.startDateFilter }}</span>
                <img
                  class="close-down"
                  :src="arrow_down"
                  alt="icon"
                />
              </button>

              <div class="dropdown-menu-content" v-show="isYearDropdownOpen">
                <div
                  v-for="year in years"
                  :key="year"
                  class="dropdown-item"
                  :class="{ selected: filter.startDateFilter === year }"
                  @click="selectYear(year)"
                >
                  <span>{{ year }}</span>
                </div>
              </div>
            </div>

            <div
              class="dropdown-wrapper"
              v-click-outside="closeEventTypeDropdown"
            >
              <button
                class="dropdown-button"
                :class="{ 'has-value': filter.eventType }"
                @click="toggleEventTypeDropdown"
                :disabled="isLoading"
              >
                <span>{{ selectedEventTypeLabel || "Event Type" }}</span>
                <img
                  class="close-down"
                  :src="arrow_down"
                  alt="icon"
                />
              </button>

              <div
                class="dropdown-menu-content"
                v-show="isEventTypeDropdownOpen"
              >
                <div
                  v-for="option in eventTypeOptions"
                  :key="option.value"
                  class="dropdown-item"
                  :class="{ selected: filter.eventType === option.value }"
                  @click="selectEventType(option)"
                >
                  <span>{{ option.label }}</span>
                </div>
              </div>
            </div>

            <div
            v-if="selectedRoomTeam.teamType === 'sports'"
              class="dropdown-wrapper"
              v-click-outside="closeAssociationDropdown"
            >
              <button
                class="dropdown-button"
                :class="{ 'has-value': filter.associationFilter }"
                @click="toggleAssociationDropdown"
                :disabled="isLoading"
              >
                <span>{{
                  filter.associationFilter || "Association"
                }}</span>
                <img
                  class="close-down"
                  :src="arrow_down"
                  alt="icon"
                />
              </button>

              <div
                class="dropdown-menu-content"
                v-show="isAssociationDropdownOpen"
              >
                <div
                  v-for="option in associationOptions"
                  :key="option"
                  class="dropdown-item"
                  :class="{ selected: filter.associationFilter === option }"
                  @click="selectAssociation(option)"
                >
                  <span>{{ option }}</span>
                </div>
              </div>
            </div>

            <div class="dropdown-wrapper" v-click-outside="closeStateDropdown">
              <button
                class="dropdown-button"
                :class="{ 'has-value': filter.stateFilter }"
                @click="toggleStateDropdown"
                :disabled="isLoading"
              >
                <span>{{
                  filter.stateFilter
                    ? filter.stateFilter.abbreviation
                    : "State"
                }}</span>
                <img
                  class="close-down"
                  :src="arrow_down"
                  alt="icon"
                />
              </button>

              <div class="dropdown-menu-content" v-show="isStateDropdownOpen">
                <div
                  v-for="option in stateOptions"
                  :key="option.name"
                  class="dropdown-item"
                  :class="{ selected: filter.stateFilter === option }"
                  @click="selectState(option)"
                >
                  <span>{{ option.abbreviation }}</span>
                </div>
              </div>
            </div>

            <!-- <button
              class="filter-button"
              :class="{ 'filter-button-active': isPastEventsActive }"
              :disabled="isPastEventsDisabled"
              @click="togglePastEventsFilter()"
            >
              Past Events
            </button> -->
            <button class="filter-button" 
              :class="{ 'filter-button-active': eventStatus }"
              :disabled="isPastEventsDisabled"
              @click="togglePastEventsFilter">Past Events</button>
          </div>
          <button
            class="reset-button"
            @click="resetFilters"
            v-if="isResetVisible"
          >
            Reset Filters
          </button>
        </div>
      </div>
    </div>
    <div class="event-section-content">
      <div v-if="teamEventInfo.length > 0" class="event-info-wrapper pb-0">
        <div
          class="event-info-sec"
          v-for="(event, index) in teamEventInfo"
          :key="index"
        >
          <div class="event-sec-top" @click="redirectToEvent(event)">
            <div class="sec-date-name">
              <!-- Display event date range using the start and end date -->
              <div class="date-time-zone d-flex">
                <span>{{
                  formatDateRange(event.exactStartDate, event.exactEndDate)
                }}</span>
                <span class="ml-1" v-if="event?.time_zone">
                  ({{ formatTimeZone(event.time_zone) }})
                </span>
              </div>
              <span class="name-event">{{ event.eventName }}</span>
            </div>
            <div class="loc-follow w-100">
              <div class="loc-content justify-content-end" v-if="event && event.status === '2'">
                <img
                  :src="link"
                  alt="link"
                  class="mr-1"
                  @click.stop="openInNewTab(event.Url)"
                />
                <span @click.stop="openInNewTab(event.Url)">{{
                  event.Url
                }}</span>
              </div>
              <div class="loc-content justify-content-end" v-else-if="formatLocation(event)">
                <img
                  :src="location"
                  alt="location"
                  class="mr-1"
                  @click.stop="openGoogleMap($event, event)"
                />
                <span @click.stop="openGoogleMap($event, event)">{{
                  formatLocation(event)
                }}</span>
              </div>
              
              <div class="attendee-sec"  v-if="goingLoader[event.id]">
                <content-placeholders :rounded="true">
                  <content-placeholders-text :lines="1" class="content-margin-reaction" />
                </content-placeholders>
              </div>
              <div class="attendee-sec" v-else>
                <!-- Dropdown Button -->
                <button 
                  type="button" 
                  @click.stop="toggleDropdown(event.id)" 
                  v-click-outside="closeDropdown" 
                  class="dropdown-btn" 
                  :class="{ 'selected': selectedOptions[event.id] !== 'Are you going?' }"
                >
                  <span>{{ selectedOptions[event.id] }}</span>
                </button>


                <!-- Dropdown Menu -->
                <div v-if="showDropdowns[event.id]" class="attendee-dropdown">
                  <button 
                    v-for="option in options" 
                    :key="option.value" 
                    @click.stop="selectOption(event.id, option)"
                    class="dropdown-option w-100"
                    :class="{ 'selected': selectedOptions[event.id] === option.label }"
                  >
                    <span>{{ option.label }}</span>
                  </button>
                </div>
              </div>
             
            </div>
          </div>

          <div class="event-sec-details">
            <div class="event-sec-left">
              <!-- Conditionally display association and event type -->
              <span class="asso-tour">
                {{ event.association ? event.association : "" }}
                {{ event.association && event.eventType ? " - " : "" }}
                {{ event.eventType }}
              </span>
              <span class="director" v-if="event.director_name"
                >Director:
                {{ event.director_name ? event.director_name : "N/A" }}</span
              >
              <div
                v-if="
                  event.event_attendee_all &&
                  event.event_attendee_all.length > 0
                "
              >
                <MatesEventData :members="event.event_attendee_all" />
              </div>
              <!-- <span v-else class="director">No teams</span> -->
            </div>

            <div class="event-sec-right">
              <div class="weather-sec">
                <WeatherData
                  v-if="isEventWithinFiveDays(event)"
                  :eventId="event.id"
                />
              </div>
              <div class="play-info" v-if="selectedRoomTeam.teamType === 'sports'">
                <div class="game-play">
                  <span>Games</span>
                  <span>{{ event.eventRecord.total_games }}</span>
                  </div>
                  <div class="game-win">
                    <span>Won</span>
                    <span>{{ event.eventRecord.win }}</span>
                  </div>
                  <div class="game-loss">
                    <span>Lost</span>
                    <span>{{ event.eventRecord.lost }}</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div
          v-if="teamEventInfo.length"
          v-observe-visibility="handleScrolledToButtom"
        ></div>
        <div class="p-3" v-if="showLoader1">
          <content-placeholders :rounded="true">
            <content-placeholders-heading :img="false" />
          </content-placeholders>
        </div>
      </div>

      <div
        class="no-event-wrap"
        v-if="teamEventInfo.length == 0 && showLoader == 0"
      >
        <img src="../../assets/Svg/no-data.svg" alt />
        <p class="header">No events found.</p>
        <p class="info">
          We couldn't find any events. Broaden your search by tweaking the filters above.
        </p>
      </div>
      <div
        class="products-list-shim"
        v-if="teamEventInfo.length == 0 && showLoader == 1"
      >
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
        <content-placeholders class="mt-3" :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from "axios";
let states = require("../../assets/js/state");
let eventType = require("../../assets/js/eventType");
import MatesEventData from "../../components/NewGameTime/Events/MatesEventData.vue";
import WeatherData from "../NewGameTime/Events/WeatherData.vue";
import servicesModal from "../../components/Teams/ChatRight/ChatEvents/servicesModal.vue";
import CreateEventDetailModal from "@/components/Teams/ChatRight/ChatEvents/CreateEventDetailModal.vue";
// import NewGame from "../Teams/Games/NewGame.vue";

export default {
  name: "TeamEventsDetail",
  components: {
    // NewGame,
    MatesEventData,
    WeatherData,
    servicesModal,
    CreateEventDetailModal
  },

  data() {
    return {
      goingLoader: {},
      newGameProps: {},
      activeAccordionId: -1,
      ComponentName: "Events",
      activeAccordionIndex: -1,
      reverse: false,
      sortKey: "",
      showLoader1: false,
      showGameShimmer: 0,
      gameList: [],
      teamId: "",
      showLoader: 0,
      teamEventInfo: [],
      page: 1,
      lastPage: 1,
      total: 0,
      eventTo: 0,
      open: false,
     
      
      isLoading: false,
      filter: {
        eventType: "",
        associationFilter: "",
        stateFilter: "",
        startDateFilter: new Date().getFullYear(),
      },
      eventStatus: false,
      stateOptions: states,
      associationOptions: [],
      eventTypeOptions: eventType,
      filterApplied: false,

      location: require("../../assets/Svg/location.svg"),
      link: require("../../assets/images/link.png"),
      arrow_down: require("../../assets/Svg/arrow-down.svg"),
      crossIcon: require("../../assets/images/cross.png"),
      isScrolled: false,
      isEventTypeDropdownOpen: false,
      isAssociationDropdownOpen: false,
      isStateDropdownOpen: false,
      isPastEventsActive: false, // Track active state for Past Events
      isPastEventsDisabled: false, // Track active state for Past Events
      isYearDropdownOpen: false,
      years: this.availableYears,
      options: [
        { label: "Going", value: "going", status: "1" },
        { label: "Not Going", value: "not-going", status: "2" },
        { label: "Maybe", value: "maybe", status: "3" }
      ],
      selectedOptions: {}, // Stores selectedOption for each event by event.id
      showDropdowns: {},
      singleEvent: {},
      previousGoing: false,
      selectedArray: [],
      availableYears: [],
      room_count: 1,
      adult_count: 1,
      exactRoomEndDate: "",
      note: "",
      exactRoomStartDate: "",
    };
  },
  props: {
    currentIsAdmin: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
  },
  // components: {
  //   EventsFilter,
  // },
  watch: {
    sortBy: function (newSortBy, oldSortBy) {
      if (newSortBy !== oldSortBy) {
        // Close the accordion when sorting is applied
        this.closeToggleAccordion(this.activeAccordionId);
      }
    },
    availableYears: {
      handler(newVal) {
        this.years = newVal;
      },
      
      deep: true, // Use this if you want to track changes within the array, e.g., object updates
      immediate: true, // Logs the initial value when the component is mounted
    },

  },
  computed: {
    isResetVisible() {
      return (
        this.filter.eventType ||
        this.filter.stateFilter ||
        this.filter.associationFilter ||
        this.eventStatus
      );
    },
    selectedEventTypeLabel() {
      const selected = this.eventTypeOptions.find(
        (option) => option.value === this.filter.eventType
      );
      return selected ? selected.label : null;
    },
    sortedEvents() {
      const events = this.teamEventInfo.slice();
      if (this.sortKey === "event") {
        events.sort((a, b) => {
          const eventA = `${a.eventType} ${a.eventName}`;
          const eventB = `${b.eventType} ${b.eventName}`;
          return this.reverse
            ? eventB.localeCompare(eventA)
            : eventA.localeCompare(eventB);
        });
      } else if (this.sortKey === "exactStartDate") {
        events.sort((a, b) => {
          const dateA = new Date(a[this.sortKey]);
          const dateB = new Date(b[this.sortKey]);

          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            return 0; // handle invalid dates
          }

          return this.reverse
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
      } else if (this.sortKey === "location") {
        events.sort((a, b) => {
          const locationA =
            (a.address || "") + " " + (a.city || "") + " " + (a.state || "");

          const locationB =
            (b.address || "") + " " + (b.city || "") + " " + (b.state || "");

          return this.reverse
            ? locationB.localeCompare(locationA)
            : locationA.localeCompare(locationB);
        });
      } else if (this.sortKey) {
        events.sort((a, b) => {
          const fieldA = a[this.sortKey];
          const fieldB = b[this.sortKey];

          if (typeof fieldA === "string" && typeof fieldB === "string") {
            return this.reverse
              ? fieldB.localeCompare(fieldA)
              : fieldA.localeCompare(fieldB);
          } else if (typeof fieldA === "number" && typeof fieldB === "number") {
            return this.reverse ? fieldB - fieldA : fieldA - fieldB;
          } else {
            return 0; // handle other types of sorting if needed
          }
        });
      }
      return events;
    },
  },

  methods: {
    redirectToEvent(item) {
      const eventId = item.id;
      this.$router.push({ name: 'SingleEventDetail', params: { id: eventId } });
      localStorage.setItem("eventOrigin", "Teams")
    },
    toggleDropdown(eventId) {
      // Toggle dropdown visibility for the specific event
      this.$set(this.showDropdowns, eventId, !this.showDropdowns[eventId]);
    },
     getStatusValue(value) {
      // Convert option value to memberStatus value
      switch (value) {
        case "going":
          return "1";
        case "not-going":
          return "2";
        case "maybe":
          return "3";
        default:
          return null;
      }
    },
    initializeSelectedOptions(eventId, loggedInUserAttendee) {
      if (!loggedInUserAttendee) {
        this.$set(this.selectedOptions, eventId, "Are you going?");
      } else {
        switch (loggedInUserAttendee.member_status) {
          case "1":
            this.$set(this.selectedOptions, eventId, "Going");
            break;
          case "2":
            this.$set(this.selectedOptions, eventId, "Not Going");
            break;
          case "3":
            this.$set(this.selectedOptions, eventId, "Maybe");
          
            break;
          default:
            this.$set(this.selectedOptions, eventId, "Are you going?");
        }
      }
    },
    closeDropdown(eventId) {
      if (eventId && this.showDropdowns[eventId]) {
        this.$set(this.showDropdowns, eventId, false);
      } else {
        // Close all dropdowns as a fallback
        Object.keys(this.showDropdowns).forEach(id => {
          this.$set(this.showDropdowns, id, false);
        });
      }
    },
    async selectStatusWithServices(status) {
     
      status = 1;
      var formData = new FormData();
      formData.append("event_id", this.singleEvent.id);
      formData.append("member_status", status);
      formData.append("services", this.selectedArray);
      formData.append("team_id", this.singleEvent.team_id);
      formData.append("room_count", this.room_count);
      formData.append("exactStartDate", this.exactRoomStartDate);
      formData.append("exactEndDate", this.exactRoomEndDate);
      formData.append("note", this.note);
      formData.append("adult_count", this.adult_count);

      if (this.userMember_id) {
        formData.append("member_id", this.userMember_id);
      }
      this.$set(this.goingLoader, this.singleEvent.id, true);
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (response.data.statusCode === 200) {
          const userId = this.userMember_id || localStorage.getItem("userId");
          const selectedServices = Array.isArray(this.selectedArray) ? this.selectedArray.join(",") : null;

          // Create the new attendee object
          const newAttendee = {
            id: localStorage.getItem("userId"), // Temporary unique ID
            event_id: this.singleEvent.id,
            team_id: this.singleEvent.team_id,
            member_id: userId,
            member_status: status,
            services: selectedServices,
            room_count: this.room_count,
            exactStartDate: this.exactRoomStartDate,
            exactEndDate: this.exactRoomEndDate,
            note: this.note,
            adult_count: this.adult_count
          };

          this.$set(this.selectedOptions, this.singleEvent.id, "Going");
          this.$set(this.goingLoader, this.singleEvent.id, false);

          // Remap eventsDetails to ensure reactivity
          this.teamEventInfo = this.teamEventInfo.map(event => {
            if (event.id === this.singleEvent.id) {
              event.event_attendee_all = [...event.event_attendee_all, newAttendee];
            }
            if (event.loggedInUserAttendee) {
                  event.loggedInUserAttendee.member_status = "1";
              } else {
                  event.loggedInUserAttendee = { member_status: "1" };
              }
            return event;
          });

          // this.$toast.success(response.data.message || "Travel details updated successfully.");
        }
      } catch (error) {
        console.error("Error updating travel details:", error);
        this.$set(this.goingLoader, this.singleEvent.id, false);
      }
    },
    selectOption(eventId, option) {
      this.singleEvent = {};
      if (option.status !== "1") {
          this.$set(this.selectedOptions, eventId, option.label);
      }
      this.closeDropdown(eventId);

      const userId = localStorage.getItem("userId");
      const statusValue = this.getStatusValue(option.value);

      // Remap teamEventInfo to ensure reactivity
      this.teamEventInfo = this.teamEventInfo.map(event => {
        if (event.id === eventId) {
            
              // Update loggedInUserAttendee status
              event.loggedInUserAttendee = event.loggedInUserAttendee || {};
              this.previousGoing = event.loggedInUserAttendee?.member_status === "1" || event.loggedInUserAttendee?.member_status === 1;

              // Update event_attendee_all: remove attendee if status is 2 or 3
              if (statusValue == '2' || statusValue == '3') {
                  event.event_attendee_all = event.event_attendee_all.filter(attendee => attendee.member_id !== userId);
                  event.loggedInUserAttendee.member_status = statusValue;

                } else {
                  event.event_attendee_all = event.event_attendee_all.map(attendee => {
                      if (attendee.member_id === userId) {
                          return { ...attendee, member_status: statusValue };
                      }
                      return attendee;
                  });
              }

              this.singleEvent = event;
          }
          return event;
      });

      const updatedEvent = this.teamEventInfo.find(e => e.id === eventId);
      if (updatedEvent) {
          this.selectStatus(updatedEvent.team_id, updatedEvent.id, option.status, this.singleEvent);
      }
    },
    async selectStatus(team_id, event_id, member_status, singleEvent) {
      
      if (this.previousGoing && member_status == 1) {
        return;
      }
      if (member_status == 1) {
        this.$root.$emit("openServicesModal", singleEvent);
      } else {
        this.editTravel = false;
        
        // Create form data
        var formData = new FormData();
        formData.append("event_id", event_id);
        formData.append("member_status", member_status);
        formData.append("team_id", team_id);

        try {
          await axios.post(
            process.env.VUE_APP_API_URL + "event/eventAttendeeSelect",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // this.getAttendee(); // Refresh attendee list after success
        } catch (error) {
          console.error("Error updating status:", error);
        }
      }
    },
    toggleYearDropdown() {
      this.isYearDropdownOpen = !this.isYearDropdownOpen;
    },
    closeYearDropdown() {
      this.isYearDropdownOpen = false;
    },
   
    selectYear(year) {
      this.filter.startDateFilter = year;

      const currentYear = new Date().getFullYear();

      if (year < currentYear) {
        this.eventStatus = true;
        this.isPastEventsDisabled = true; // Disable the button when a past year is selected
      } else {
        this.eventStatus = false;
        this.isPastEventsDisabled = false; // Enable the button for the current or future year
      }
      this.page = 1;
      this.teamEventInfo = [];
      this.getTeamEvents(); // Trigger API call
      this.closeYearDropdown();
    },
    clearYear() {
      this.filter.startDateFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
      this.closeYearDropdown();
    },

    // Association Dropdown
    toggleAssociationDropdown() {
      this.isAssociationDropdownOpen = !this.isAssociationDropdownOpen;
      this.isStateDropdownOpen = false; // Close state dropdown when opening association dropdown
    },
    closeAssociationDropdown() {
      this.isAssociationDropdownOpen = false;
    },
    selectAssociation(option) {
      this.filter.associationFilter = option;
      this.getTeamEvents();
      this.closeAssociationDropdown();
    },
    clearAssociation() {
      this.filter.associationFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
      this.closeAssociationDropdown();
    },

    // State Dropdown
    toggleStateDropdown() {
      this.isStateDropdownOpen = !this.isStateDropdownOpen;
      this.isAssociationDropdownOpen = false; // Close association dropdown when opening state dropdown
    },
    closeStateDropdown() {
      this.isStateDropdownOpen = false;
    },
    selectState(option) {
      this.filter.stateFilter = option;
      this.teamEventInfo = [];
      this.page = 1;
      this.getTeamEvents();
      this.closeStateDropdown();
    },
    clearState() {
      this.filter.stateFilter = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
      this.closeStateDropdown();
    },

    toggleEventTypeDropdown() {
      this.isEventTypeDropdownOpen = !this.isEventTypeDropdownOpen;
    },
    closeEventTypeDropdown() {
      this.isEventTypeDropdownOpen = false;
    },
    selectEventType(option) {
      this.filter.eventType = option.value;
      this.teamEventInfo = [];
      this.page = 1;
      this.getTeamEvents();
      this.closeEventTypeDropdown();
    },
    clearEventType() {
      this.filter.eventType = "";
      this.page = 1;
      this.filterApplied = false;
      this.teamEventInfo = [];
      this.getTeamEvents();
      this.closeEventTypeDropdown();
    },

    resetFilters() {
      this.eventStatus = false;
      this.isPastEventsDisabled = false;
      this.filter.eventType = "";
      this.filter.stateFilter = "";
      this.filter.associationFilter = "";
      this.filter.startDateFilter = new Date().getFullYear();
      this.teamEventInfo = [];
      this.page = 1;
      this.getTeamEvents();
    },
    formatTimeZone(timeZone) {
      if (timeZone && typeof timeZone === "string") {
        return timeZone.replace(/\(UTC[^)]+\)\s*/, "").trim(); // Removes text like "(UTC-06:00)"
      }
      return timeZone; // Return the original value if it's not a valid string
    },
    formatDateRange(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const startDateString = start.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      const endDateString = end.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      const startYear = start.getFullYear();
      const endYear = end.getFullYear();

      if (startYear === endYear) {
        return `${startDateString} to ${endDateString}, ${startYear}`;
      } else {
        return `${startDateString}, ${startYear} to ${endDateString}, ${endYear}`;
      }
    },
    isEventWithinFiveDays(event) {
        if (!event?.startDate || !event?.endDate) return false;

        if (event && event.status === '2' && event.Url) {
            console.log("Skipping weather data fetch for online event.");
            return;
          }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Calculate differences in days
        const daysFromTodayToStart = (startDate - today) / (1000 * 60 * 60 * 24);
        const daysFromTodayToEnd = (endDate - today) / (1000 * 60 * 60 * 24);

        // Check if event starts or ends within 5 days
        const startsWithinFiveDays = daysFromTodayToStart >= 0 && daysFromTodayToStart <= 5;
        const endsWithinFiveDays = daysFromTodayToEnd >= 0 && daysFromTodayToEnd <= 5;
        const ongoingToday = today >= startDate && today <= endDate;

        // **EXTRA CONDITION**: If event duration is too long, exclude it
        const eventDuration = (endDate - startDate) / (1000 * 60 * 60 * 24);
        if (eventDuration > 10) return false; // Example: Ignore events longer than 10 days

        return startsWithinFiveDays || endsWithinFiveDays || ongoingToday;
      },
    openGoogleMap(event, item) {
      event.preventDefault(); // Prevents <router-link> navigation
      event.stopPropagation(); // Stops event bubbling
      
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];
      
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
    formatLocation(event) {
      let location = "";

      if (event.city) {
        // Add comma if address exists, else just city
        location += (location ? ", " : "") + event.city;
      }

      if (event.state) {
        // Add comma if address or city exists, else just state
        location += (location ? ", " : "") + event.state;
      }

      return location;
    },
    openInNewTab(url) {
      if (url) {
        window.open(url, "_blank", "noopener,noreferrer");
      }
    },

    handleSortChanged({ sortBy, sortDesc }) {
      this.sortKey = sortBy;
      this.reverse = sortDesc;
    },
    handleClick(evt) {
      if (evt.target.tagName !== "INPUT") {
        this.open = true;
      }
    },
    // openGoogleMap(item) {
    //   const strlatLng = item["lan"] + "," + item["long"];
    //   const urlSuffix = strlatLng
    //     ? strlatLng
    //     : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

    //   window.open(
    //     "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
    //     "_blank"
    //   );
    // },
    shouldDisplayAssociation(item) {
      return (
        this.selectedRoomTeam.sportType === "Softball (Slow Pitch)" ||
        this.selectedRoomTeam.sportType === "Softball (Fast Pitch)" ||
        (this.selectedRoomTeam.sportType === "Softball" &&
          item.association != null &&
          item.association !== "" &&
          item.association !== "null")
      );
    },
    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (isVisible) {
        this.page++;
        this.getTeamEvents();
      }
    },
    toggleAccordion(event, eventId) {
      this.newGameProps = { event };
      if (this.activeAccordionId === eventId) {
        this.teamEventInfo.forEach((teamEventInfo) => {
          if (teamEventInfo.id === eventId) {
            teamEventInfo._showDetails = false;
          }
        });
        this.activeAccordionId = -1;
      } else {
        this.teamEventInfo.forEach((teamEventInfo) => {
          teamEventInfo._showDetails = teamEventInfo.id === eventId;
        });
        this.activeAccordionId = eventId;
        this.getGames(event);
      }
    },

    closeToggleAccordion(eventId) {
      this.teamEventInfo.forEach((teamEventInfo) => {
        if (teamEventInfo.id === eventId) {
          teamEventInfo._showDetails = false;
        }
      });
      this.activeAccordionId = -1;
    },
    async getGames(event) {
      this.showGameShimmer = 1;
      this.gameList = [];
      var formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("team_id", event.team_id);
      await axios
        .post(process.env.VUE_APP_API_URL + "game/gameListing", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.gameList = response.data.data;
          event.showGameList = true;
          this.showGameShimmer = 0;
        });
    },
    callModal(game, event) {
      var combineUrl = event.team_id + "-" + game.guid;
      const urlToOpen = `/score-game/${combineUrl}`;
      window.location.href = urlToOpen;
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = false;
      }
    },
    togglePastEventsFilter() {
      if (this.isPastEventsDisabled) return; // Prevent toggling when disabled
      this.eventStatus = !this.eventStatus;
      this.teamEventInfo = [];
      this.page = 1;
      this.getTeamEvents(); 
    },
    async getTeamEvents() {
      this.showLoader = 1;
      this.showLoader1 = true;
      this.isLoading = true;

      // let formattedStartDate = "";
      // if (this.filter.startDateFilter) {
      //   const startDateParts = this.filter.startDateFilter.split("/");
      //   formattedStartDate = `${startDateParts[2]}/${startDateParts[1]}/${startDateParts[0]}`;
      // }

      const formData = new FormData();
      formData.append("team_id", this.teamId);

      formData.append("event_type", this.filter.eventType);
      formData.append("selectedYear", this.filter.startDateFilter);
      formData.append("association", this.filter.associationFilter);
      if (this.filter.stateFilter) {
        formData.append("region", this.filter.stateFilter.name);
        formData.append("abbreviation", this.filter.stateFilter.abbreviation);
      }
      if (this.eventStatus != "") {
        formData.append("eventStatus", this.eventStatus);
      } else {
        formData.append("eventStatus", false);
      }
      if (
        (this.filter.eventType ||
          this.filter.startDateFilter ||
          this.filter.associationFilter ||
          this.filter.stateFilter) &&
        !this.filterApplied
      ) {
        this.page = 1;
        this.teamEventInfo = [];
        this.filterApplied = true;
      }

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}event/teamEventListings?page=${this.page}`,
          formData
        );

        if (response.data.statusCode === 200) {
          const newEventData = response.data.data.events.data;
          this.availableYears = response.data.data.availableYears;
          if (this.page === 1) {
            this.teamEventInfo = [];
          }
          this.teamEventInfo = this.teamEventInfo.concat(newEventData);
          this.teamEventInfo.forEach(event => {
            this.initializeSelectedOptions(event.id, event.loggedInUserAttendee);
          });

          this.teamEventInfo.sort((a, b) => {
            const dateAStart = new Date(a.exactStartDate);
            const dateBStart = new Date(b.exactStartDate);
            const dateAEnd = new Date(a.exactEndDate);
            const dateBEnd = new Date(b.exactEndDate);

            // Compare start dates first
            if (dateAStart.getTime() !== dateBStart.getTime()) {
              return dateAStart - dateBStart;
            } else {
              // If start dates are the same, compare end dates
              return dateAEnd - dateBEnd;
            }
          });

          this.lastPage = response.data.data.events.last_page;
        } else {
          console.error(
            "Error:",
            response.data.message || "Unknown error occurred"
          ); // Log error message
          this.errorMessage = response.data.message || "Unknown error occurred";
        }
      } catch (error) {
        console.error("Failed to fetch data:", error); // Log error message
        this.errorMessage = "Failed to fetch data";
      } finally {
        this.showLoader = 0;
        this.showLoader1 = false;
        this.isLoading = false;
      }
    },
    getFilters() {
      (this.teamEventInfo = []), (this.showLoader = 1);
      this.showLoader1 = true;
      this.isLoading = true;
      const formData = new FormData();
      formData.append("team_id", this.teamId);

      formData.append("event_type", this.filter.eventType);
      formData.append("startDate", this.filter.startDateFilter);
      formData.append("association", this.filter.associationFilter);
      formData.append("region", this.filter.stateFilter);
      if (this.filter.eventStatus != "") {
        formData.append("eventStatus", this.filter.eventStatus);
      } else {
        formData.append("eventStatus", false);
      }
      if (
        this.filter.eventType ||
        this.filter.startDateFilter ||
        this.filter.associationFilter ||
        this.filter.stateFilter
      ) {
        this.page = 1;
        this.teamEventInfo = [];
      }

      axios
        .post(
          `${process.env.VUE_APP_API_URL}event/teamEventListings?page=${this.page}`,
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.total = response.data.data.total;
            this.teamEventInfo.push(...response.data.data.data);
            this.teamEventInfo.sort((a, b) => {
              const dateAStart = new Date(a.exactStartDate);
              const dateBStart = new Date(b.exactStartDate);
              const dateAEnd = new Date(a.exactEndDate);
              const dateBEnd = new Date(b.exactEndDate);

              // Compare start dates first
              if (dateAStart.getTime() !== dateBStart.getTime()) {
                return dateAStart - dateBStart;
              } else {
                // If start dates are the same, compare end dates
                return dateAEnd - dateBEnd;
              }
            });
            this.lastPage = response.data.data.last_page;
            this.showLoader = 0;
            this.showLoader1 = false;
            this.isLoading = false;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
            this.showLoader1 = false;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          (this.errorMessage = "Failed to fetch data"), error;
          this.showLoader = 0;
          this.showLoader1 = false;
          this.isLoading = false;
        });
    },

    checkDateInput() {
      if (!this.filter.startDateFilter) {
        this.filter.startDateFilter = "";
        this.page = 1;
        this.filterApplied = false;
        this.teamEventInfo = [];
        this.getTeamEvents();
      }
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getAssociation();
      this.getTeamEvents();
    }
    this.$root.$on("refreshEventGame", (event) => {
      this.getGames(event);
    });
    this.$root.$on("fetchEvents", () => {
      this.page = 1;
      this.teamEventInfo = [];
      this.getTeamEvents();
    });
     this.$root.$on(
    "attendeesServiceForMyEvent",
    (
      data,
      room_count,
      adultQuantity,
      note,
      exactRoomStartDate,
      exactRoomEndDate
    ) => {
      this.selectedArray = data;
      this.room_count = room_count;
      this.note = note;
      this.adult_count = adultQuantity;
      this.exactRoomEndDate = exactRoomEndDate;
      this.exactRoomStartDate = exactRoomStartDate;
      this.selectStatusWithServices();
    }
  );
  },
};
</script>
<style scoped>
.table thead th {
  min-width: 150px;
}
.eye-icon {
  width: 30px;
}
.no-game-section {
  height: 150px !important;
}
.no-products-in-shop {
  height: auto !important;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
.attendee-dropdown .dropdown-option:nth-child(1) {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
}

.attendee-dropdown .dropdown-option:nth-child(2) {
    padding-top: 16px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}

.attendee-dropdown .dropdown-option:nth-child(3) {
    padding-top: 16px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 0px;

}
</style>