<template>
  <div>
    <MembersInfos
      :selectedRoomTeam="selectedRoomTeam"
      :currentUserId="currentUserId"
      :currentIsAdmin="currentIsAdmin"
      :currentIsFan="currentIsFan"
      :currentTeamIsActive="currentTeamIsActive"
    />
  </div>
</template>

<script>
import MembersInfos from "../Teams/ChatRight/ChatSettingComponents/MembersInfos.vue";
export default {
  name: "GameTimeTeammates",
  components: {
    MembersInfos,
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
};
</script>